import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AlertWrapper from "./utils/alert";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import reducers from "./store/reducers";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import "./utils/lib";

const store = createStore(combineReducers(reducers));
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c5038fa7cac24d958e0e77ca34a5c0f3@sentry.io/5182379",
    environment: "prod",
    attachStacktrace: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AlertWrapper>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </AlertWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
