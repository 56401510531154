import React from "react";
import axios from "axios";
import { connect } from "react-redux";

export default function withTasks(Component) {
  class WithTasks extends React.Component {
    setVisibleTasks() {
      const { setTasks } = this.props;

      return axios.get("/task/userTasks").then(data => setTasks(data.tasks));
    }

    setExplicitVisibleTasks(filterTaskIds = []) {
      return axios
        .get(`/task/userTasks?filterTaskIds=${filterTaskIds.join(",")}`)
        .then(data => data.tasks);
    }

    setTaskView(id) {
      return axios.head(`/task/view/${id}`);
    }

    doTaskConverse(id, message) {
      return axios
        .post(`/task/converse/${id}`, { message })
        .then(this.setVisibleTasks.bind(this));
    }

    createTask(message) {
      return axios.post(`/task/create`, { message });
    }

    getTaskDetails(id) {
      const { tasks } = this.props;

      return tasks.find(task => task.id === id);
    }

    render() {
      const { tasks } = this.props;

      return (
        <Component
          {...this.props}
          tasks={tasks}
          setVisibleTasks={this.setVisibleTasks.bind(this)}
          setExplicitVisibleTasks={this.setExplicitVisibleTasks.bind(this)}
          setTaskView={this.setTaskView.bind(this)}
          doTaskConverse={this.doTaskConverse.bind(this)}
          createTask={this.createTask.bind(this)}
          getTaskDetails={this.getTaskDetails.bind(this)}
        />
      );
    }
  }

  return connect(
    state => {
      return {
        tasks: state.Task,
      };
    },
    dispatch => ({
      resetTasks: payload => {
        dispatch({
          type: "RESET_TASKS",
          payload: payload,
        });
      },
      setTasks: payload => {
        dispatch({
          type: "SET_TASKS",
          payload: payload,
        });
      },
      addTask: payload => {
        dispatch({
          type: "ADD_TASK",
          payload: payload,
        });
      },
      addConversation: payload => {
        dispatch({
          type: "ADD_CONVERSATION",
          payload: payload,
        });
      },
      mutateTask: payload => {
        dispatch({
          type: "MUTATE_TASK",
          payload: payload,
        });
      },
      bulkAddTask: payload => {
        dispatch({
          type: "BULK_ADD_TASK",
          payload: payload,
        });
      },
    })
  )(WithTasks);
}
