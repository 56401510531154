import React from "react";
import { connect } from "react-redux";

import Firebase, {
  analytics,
  database,
  // messaging,
  geodatabase,
  firestore,
} from "../utils/firebase";
import { useAlert } from "../utils/alert";

export default function withFirebase(Component) {
  class WithFirebase extends React.Component {
    constructor() {
      super();

      this.locationWatcher = null;
    }

    componentWillUnmount() {
      window.navigator.geolocation.clearWatch(this.locationWatcher);
    }

    setEventHandlers() {
      // window.navigator.permissions
      //   .query({ name: "notifications" })
      //   .then(({ state }) => {
      //     if (state === "granted") {
      //       messaging.getToken().then((currentToken) => {
      //         if (currentToken) {
      //           this.updateFCMToken(currentToken);
      //         }
      //       });
      //     }
      //   });

      // messaging.onTokenRefresh(() => {
      //   messaging
      //     .getToken()
      //     .then(this.updateFCMToken.bind(this))
      //     .catch(err => {
      //       console.log("Unable to retrieve refreshed token ", err);
      //     });
      // });

      this.initLocationWatcher();
      return;
    }

    initLocationWatcher() {
      if (this.locationWatcher) return;
      const { user } = this.props;

      this.locationWatcher = window.navigator.permissions
        .query({ name: "geolocation" })
        .then(({ state }) => {
          if (state === "granted") {
            window.navigator.geolocation.watchPosition(
              ({ coords }) => {
                const { latitude, longitude } = coords;
                const location = new firestore.GeoPoint(latitude, longitude);
                if (
                  user.location?.latitude !== latitude ||
                  user.location?.longitude !== longitude
                ) {
                  this.updateUserLocation(location);
                }
              },
              () => {
                useAlert.show({
                  level: "info",
                  content: (
                    <span>
                      {Firebase.config[
                        "UI_LOCATION_ACCESS_REQUIRED"
                      ].asString()}
                    </span>
                  ),
                });
              },
              { enableHighAccuracy: true, maximumAge: 300000 }
            );
          } else if (user.completedTutorial && state === "denied") {
            useAlert.show({
              level: "info",
              content: (
                <span>
                  {Firebase.config["UI_LOCATION_ACCESS_REQUIRED"].asString()}
                </span>
              ),
            });
          }
        });
    }

    updateFCMToken(token) {
      const { user, setUserInStore } = this.props;
      return database
        .collection("users")
        .doc(user.uid)
        .update({ webFCMToken: token })
        .then(() => {
          setUserInStore({
            ...user,
            webFCMToken: token,
          });
        });
    }

    updateUserLocation(location) {
      const { user, setUserInStore } = this.props;
      return new Promise((resolve, reject) => {
        geodatabase
          .collection("locations")
          .doc(user.uid)
          .update({ coordinates: location })
          .then(() => {
            setUserInStore({
              ...user,
              location: location,
            });
            resolve(this.initLocationWatcher());
          })
          .catch((err) => {
            if (err.name === "FirebaseError" && err.code === "not-found") {
              setTimeout(() => {
                this.updateUserLocation(location).then((watcherPromise) => {
                  resolve(watcherPromise);
                });
              }, 2000);
            } else {
              reject(err);
            }
          });
      });
    }

    async setUser(user) {
      const { setUserInStore } = this.props;

      return database
        .collection("users")
        .doc(user.uid)
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          const completedTutorial = !!localStorage.getItem(
            "MAYO_TUTORIAL_COMPLETED"
          );
          return {
            uid: user.uid,
            email: user.email,
            webFCMToken: null,
            ...data,
            completedTutorial: completedTutorial,
            claims: user.claims || {},
          };
        })
        .then((userPayload) => {
          setUserInStore(userPayload);
          analytics.setUserProperties({ uid: userPayload.uid });
          analytics.logEvent("user_authorization", { uid: userPayload.uid });
          analytics.logEvent("login", { uid: userPayload.uid });
          if (!userPayload.completedTutorial) {
            analytics.logEvent("first_open");
          }

          this.setEventHandlers();
          return userPayload;
        });
    }

    render() {
      // const { user } = this.props;

      return (
        <Component
          {...this.props}
          firebase={Firebase}
          // user={user}
          // setUser={this.setUser.bind(this)}
          // updateFCMToken={this.updateFCMToken.bind(this)}
          // updateUserLocation={this.updateUserLocation.bind(this)}
        />
      );
    }
  }

  return connect(
    (state) => {
      return {
        user: state.User,
      };
    },
    (dispatch) => ({
      setUserInStore: (payload) => {
        dispatch({
          type: "SET_USER",
          payload: payload,
        });
      },
    })
  )(WithFirebase);
}
