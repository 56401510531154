import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
import { getFirestore } from "firebase/firestore";

import { GeoFirestore } from "geofirestore";

const app = initializeApp({
  apiKey: process.env.REACT_APP_GCP_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: "1:420996576853:web:75c07edcdb1b9ec05dc6dc",
  measurementId: "G-R13Q8BJBQ2",
});

let analytics = {
  logEvent: (...args) => {
    console.log("Fired Analytics:logEvent", args);
  },
  setUserProperties: (...args) => {
    console.log("Fired Analytics:setUserProperties", args);
  },
};

if (process.env.NODE_ENV === "production") {
  analytics = {
    logEvent: logEvent.bind(this, getAnalytics()),
    setUserProperties: setUserProperties.bind(this, getAnalytics()),
  };
}

const firestore = getFirestore;
const database = firestore();
const geodatabase = new GeoFirestore(database);
const auth = getAuth();
// const messaging = firebase.messaging();
// messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_MESSAGING_KEY);

const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  API_TASK_POLL_INTERVAL: 30000,
  API_ERR_CODE_GENERIC_ERR: "Something went wrong, try again later.",
};

const FirebaseObj = {
  auth: auth,
  instance: app,
  analytics: analytics,
  // messaging: messaging,
  firestore: firestore,
  config: remoteConfig,
};

fetchAndActivate()
  .then((config) => {
    FirebaseObj.config = config;
  })
  .catch((err) => {});

export { analytics, auth, database, /** messaging ,*/ geodatabase, firestore };
export default FirebaseObj;
