const MayoFactory = function() {};

MayoFactory.prototype.isMobile = function() {
  return window.innerWidth <= 800 && window.innerHeight <= 800;
};

MayoFactory.prototype.isRobot = function() {
  return window.__IS_ROBOT__;
};

MayoFactory.prototype.MayoWebViewHandler = function() {
  return {
    getUserId() {
      if (!window.MayoWebViewHandler.getUserId) return null;

      return window.MayoWebViewHandler?.getUserId();
    },
    getIdToken() {
      if (!window.MayoWebViewHandler.getIdToken) return null;

      return window.MayoWebViewHandler?.getIdToken();
    },
    getViewData() {
      if (!window.MayoWebViewHandler.getViewData) return {};

      return window.MayoWebViewHandler?.getViewData();
    },
    closeWebView(data = null) {
      console.log("WEBVIEW_CLOSED");

      window.MayoWebViewHandler.closeWebView &&
        window.MayoWebViewHandler.closeWebView();
    },
    showMessage(message, type = "ERROR") {
      if (type === "ERROR") {
        console.error(message);
      } else {
        console.log(message);
      }

      window.MayoWebViewHandler.showMessage &&
        window.MayoWebViewHandler.showMessage(message, type);
    },
  };
};

window.MayoFactory = new MayoFactory();
