import React, { lazy, Suspense } from "react";
import axios from "axios";
import { Switch, Route, withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { FullScreenLoader } from "./utils/loaders";
import withTasks from "./hoc/withTasks";
import withFirebase from "./hoc/withFirebase";
import { useAlert } from "./utils/alert";

import "./static/scss/app.scss";

window.setTitle = function (title) {
  document.title = `Mayo - ${title}`;
};
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// const HomeComponent = lazy(() =>
//   import(
//     /* webpackChunkName: "mayo.tutorial", webpackPrefetch: true */ "./components/Home"
//   )
// );

// const TutorialComponent = lazy(() =>
//   import(
//     /* webpackChunkName: "mayo.tutorial", webpackPrefetch: 10 */ "./components/Tutorial"
//   )
// );

const ReportComponent = lazy(() =>
  import(
    /* webpackChunkName: "mayo.report", webpackPrefetch: 10 */ "./components/Report"
  )
);

const ReportFormComponent = lazy(() =>
  import(
    /* webpackChunkName: "mayo.report-form", webpackPrefetch: 10 */ "./components/ReportForm"
  )
);

const Error404Component = lazy(() =>
  import(
    /* webpackChunkName: "mayo.tutorial", webpackPrefetch: 10 */ "./components/ErrorPages/Error404"
  )
);

class App extends React.Component {
  state = {
    isLoaded: true,
  };

  componentDidMount() {
    const { firebase } = this.props;
    // firebase
    //   .auth()
    //   .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    //   .then(this.authListener.bind(this));

    axios.interceptors.response.use(
      (response) => response.data.data,
      (error) => {
        const injectedError = new Error(error);
        injectedError.isApiError = true;

        const errorCodes = error.response.data?.error_codes;

        if (!errorCodes) {
          injectedError.errorMessages = [
            {
              name: "API_ERR_CODE_API_ERR_CODE_GENERIC_ERR",
              value: firebase.config.API_ERR_CODE_GENERIC_ERR.asString(),
            },
          ];
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          injectedError.errorMessages = [
            {
              name: "UNAUTHORIZED",
              value: "Please login to continue.",
            },
          ];
        } else {
          injectedError.errorMessages = errorCodes.map((errorCode) => ({
            name: `API_ERR_CODE_${errorCode}`,
            value: (
              firebase.config[`API_ERR_CODE_${errorCode}`] ||
              firebase.config["API_ERR_CODE_GENERIC_ERR"]
            ).asString(),
          }));
        }

        injectedError.message = injectedError.errorMessages[0]?.value;
        return Promise.reject(injectedError);
      }
    );
  }

  authListener() {
    const { firebase, setUser } = this.props;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user).then(this.loginHelper.bind(this));
        Sentry.configureScope((scope) =>
          scope.setUser({
            id: user.uid,
            authType: "anonymous",
          })
        );
      } else {
        firebase
          .auth()
          .signInAnonymously()
          .catch((err) => {
            useAlert.show({
              level: "danger",
              content: (
                <span>
                  {firebase.config["API_ERR_CODE_GENERIC_ERR"].asString()}
                </span>
              ),
            });
          });
      }
    });
  }

  loginHelper(user) {
    const { firebase } = this.props;

    return firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${idTokenResult.token}`;
      })
      .then(() => {
        this.setState(
          {
            isLoaded: true,
          },
          () => !user.completedTutorial && this.props.history.push("/tutorial")
        );
      });
  }

  render() {
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return <FullScreenLoader />;
    }

    return (
      <Suspense fallback={<FullScreenLoader />}>
        <Switch>
          {/*<Route exact path="/tutorial" component={TutorialComponent} />
          <Route
            exact
            path={["/", "/conversations/:taskId"]}
            component={HomeComponent}
          />*/}
          <Route exact path="/report" component={ReportComponent} />
          <Route exact path="/report-form" component={ReportFormComponent} />
          <Route exact path="/404" component={Error404Component} />
          <Route component={Error404Component} />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(withTasks(withFirebase(App)));
