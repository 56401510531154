import React from "react";
import "../static/scss/components/_loaders.scss";

export function FullScreenLoader() {
  return (
    <div className="loaders_full-screen">
      <div className="container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
}
