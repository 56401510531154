// reducers
import { User } from "./User";
import { Task } from "./Task";

const allReducers = {
  User: User,
  Task: Task,
};

export default allReducers;
