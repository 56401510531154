import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import "../static/scss/components/_alert.scss";

const AlertDurations = {
  VERY_LONG: Symbol("VERY_LONG"),
  LONG: Symbol("LONG"),
  SHORT: Symbol("SHORT"),
};

const useAlert = {
  alertEle: null,
  show(config = {}) {
    if (!this.alertEle) return;
    const alertConfig = Object.assign(
      { position: "bottom", level: "info", content: "" },
      config
    );

    let timeoutDuration = false;
    switch (alertConfig.duration) {
      case AlertDurations.VERY_LONG:
        timeoutDuration = 10000;
        break;
      case AlertDurations.LONG:
        timeoutDuration = 7500;
        break;
      case AlertDurations.SHORT:
        timeoutDuration = 4500;
        break;
      default:
        timeoutDuration = false;
        break;
    }

    timeoutDuration &&
      setTimeout(() => {
        this.hide();
      }, timeoutDuration);

    const alertItem = (
      <div
        className="alert-wrapper"
        data-position={alertConfig.position}
        onClick={() => {
          !timeoutDuration && this.hide();
          alertConfig.onClick && alertConfig.onClick();
        }}
      >
        <div className={`alert alert-${alertConfig.level}`}>
          {alertConfig.content}
        </div>
      </div>
    );

    ReactDOM.render(alertItem, this.alertEle);
  },
  hide() {
    this.alertEle.querySelector(".alert-wrapper").classList.add("closed");
    setTimeout(() => {
      ReactDOM.render(null, this.alertEle);
    }, 250);
  },
};

function AlertWrapper(props) {
  const alertEle = useRef();

  useEffect(() => {
    useAlert.alertEle = alertEle.current;
  }, []);

  return (
    <React.Fragment>
      {props.children}
      <div className="alert-container" ref={alertEle} />
    </React.Fragment>
  );
}

export { useAlert };
export default AlertWrapper;
