export const User = (state = null, action) => {
  switch (action.type) {
    case "SET_USER":
      state = action.payload;
      break;

    default:
      break;
  }

  return state;
};
