const COLOR_CODES = [
  "#08BBDB",
  "#FC8FA3",
  "#9CD72F",
  "#ED801F",
  "#B664C4",
  "#4A4A4A",
  "#4FB5B2",
  "#2F96FF",
  "#E86D5D",
  "#1DAE73",
  "#AC664C",
  "#508FBC",
  "#BCCB4C",
  "#7C3EC1",
  "#D36679",
  "#5AC7CF",
  "#CAA63C",
];

const TASK_STATUS_CODES = {
  ONGOING: "ONGOING",
  SILENT: "SILENT",
  COMPLETED_FULFILLED: "COMPLETED_FULFILLED",
  COMPLETED_NOT_FULFILLED: "COMPLETED_NOT_FULFILLED",
  EXPIRED_TIME_EXCEED: "EXPIRED_TIME_EXCEED",
  EXPIRED_OUT_OF_AREA: "EXPIRED_OUT_OF_AREA",
};

export const Task = (state = [], action) => {
  switch (action.type) {
    case "RESET_TASKS":
      state = [];
      break;

    case "SET_TASKS":
      state = action.payload;
      break;

    case "BULK_ADD_TASK":
      state = [...state, ...action.payload];
      break;

    case "ADD_TASK":
      state = [action.payload, ...state];
      break;

    case "MUTATE_TASK":
      {
        const taskIndex = state.findIndex(
          task => task.id,
          action.payload.taskId
        );
        if (taskIndex > -1) {
          state[taskIndex] = action.payload;
        }
      }
      break;

    case "ADD_CONVERSATION":
      {
        const taskIndex = state.findIndex(
          task => task.id,
          action.payload.taskId
        );
        if (taskIndex > -1) {
          state[taskIndex].conversations.push(action.payload.data);
        }
      }
      break;

    default:
      break;
  }

  if (state) {
    state = state.map(task => {
      let colorCounter = 0;
      const userMap = {
        [task.userId]: "#c2c2c2",
      };

      return {
        ...task,
        title: task.conversations[0]?.message,
        isReadonly:
          task.status !== TASK_STATUS_CODES.ONGOING &&
          task.status !== TASK_STATUS_CODES.SILENT,
        conversations: task.conversations.map(conversation => {
          let userColorCode = userMap[conversation.userId];
          if (!userColorCode) {
            userMap[conversation.userId] =
              COLOR_CODES[colorCounter % COLOR_CODES.length];
            colorCounter++;
            userColorCode = userMap[conversation.userId];
          }

          return {
            ...conversation,
            colorCode: userColorCode,
            message: conversation.message,
          };
        }),
      };
    });
  }

  return state;
};
